<template>
  <div class="content content-fixed" style="padding-top: 80px; height: calc(100vh - 0px); overflow-y: scroll">
    <div class="container pd-0-f">
      <h6 class="bd-b pd-b-5">ТОРГУУЛИЙН ТӨЛБӨР ТӨЛӨХ БОЛОМЖ</h6>
      <div class="row no-gutters">
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/state.jpg" style="max-width: 100px" class="rounded bd" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
            Төрийн банкны салбар дээр биеэр очиж төлбөр төлөх, <a href="https://www.ibank.mn/" target="_blank">“Гялс банк”</a> цахим хуудас, <a href="https://www.ibank.mn/" target="_blank">“Гялс банк үйлчилгээний смарт аппликейшн”</a>-аар дамжуулж цахим хэлбэрээр төлөх.
          </div>
        </div>
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/golomt.jpg" style="max-width: 100px" class="rounded bd" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
            Голомт банкны салбар дээр биеэр очиж төлбөр төлөх, <a href="https://www.egolomt.mn/" target="_blank">“Интернет банк”</a> цахим хуудсаар, <a href="https://www.golomtbank.com/retail/digital-bank/smart-bank">“Смарт банк”</a>, <a href="https://socialpay.mn/">“SocialPay”</a> гар утасны аппликейшнаар дамжуулж цахим хэлбэрээр төлөх.
          </div>
        </div>
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/tdb.gif" style="max-width: 100px" class="rounded bd" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
            Худалдаа хөгжлийн банкны салбар төвүүдээр биеэр очиж торгуулийн төлбөр төлөх.
          </div>
        </div>
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/gerege.jpg" style="max-width: 100px" class="rounded bd" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
              Гэрэгэ системсийн үйлчилгээний төвүүдэд байрлах “Гэрэгэ киоск” машинаар дамжуулж биеэр төлөх байршилын мэдээллийг <a href="https://gerege.mn/%d0%ba%d0%b8%d0%be%d1%81-%d0%b1%d0%b0%d0%b9%d1%80%d1%88%d0%b8%d0%bb/" target="_blank"></a>энэ дээр дарж авна уу. Мөн “Гэрэгэ аппликейшн”-ийг гар утсандаа суулгаж цахим хэлбэрээр төлөх. Гар утасны аппликейшн татах хаяг <a href="https://gerege.mn/%d0%b3%d1%8d%d1%80%d1%8d%d0%b3%d1%8d-%d0%b0%d0%bf%d0%bf%d0%bb%d0%b8%d0%ba%d0%b5%d0%b9%d1%88%d0%bd/">www.gerege.mn </a>
            </div>
        </div>
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/smartcar.png" style="max-width: 100px" class="rounded" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
            <a href="https://smartcar.mn" target="_blank"> Smartcar.mn</a> цахим хуудсаар дамжуулж торгуулийн мэдээллийг лавлаж, төлөлт хийх.
          </div>
        </div>
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/emongolia.png" style="max-width: 100px" class="rounded" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
            Төрийн цахим үйлчилгээний нэгдсэн портал Е-Монголиа системээр дамжуулж өөрийн нэр дээрх болон тээврийн хэрэгслийн дугаар торгуулийн мэдээлэл лавлах, торгуулийн төлбөр төлөх боломжтой. <a href="https://www1.e-mongolia.mn/service/avto-teevriin-kheregsel-bolon-joloochiin-torguuliin-tulbur-tulukh" target="_blank"> Энэ дээр</a> дарж орно уу.
          </div>
        </div>
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/ebarimt.svg" style="max-width: 100px" class="rounded bd" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
						  <a href="https://ebarimt.mn/" target="_blank">Ebarimt.mn</a> цахим төлбөрийн системээр дамжуулж торгуулийн мэдээллийг лавлаж, төлөлт хийх.            
          </div>
        </div>
        <div class="col-4 pd-y-10 d-flex align-items-center justify-content-center">
          <img src="/torguuli.png" style="max-width: 100px" class="rounded bd" />
        </div>
        <div class="col-8 pd-y-10">
          <div class="text-left mg-l-10">
            <a href="http://torguuli.police.gov.mn/main/payments" target="_blank"> Torguuli.police.gov.mn</a> цахим хуудсаар дамжуулж торгуулийн мэдээллийг лавлаж, төлөлт хийх.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { request } from '@/api/all';
import { mapActions } from "vuex";


export default {
  name: 'Home',
  data(){
    return{
      //list: []
    }
  },
  mounted() {
    //console.log(this.$route.params.id);
    // this.handleLoading({ isLoading: true });
    // request({
    //   api: { method: 'GET', url: 'Penalty/SmsList'  },
    //   params:{id: this.$route.params.id},
    //   store: this.$store
    // }).then(res => {
    //   this.handleLoading({ isLoading: false });
    //   if (res !== false) {
    //     this.list= res.data.penalty;
    //     console.log(res);
    //   }
    // });
  },
  methods:{
    ...mapActions(["handleLoading"]),
  }
}
</script>
