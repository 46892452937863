<template>
  <div class="content content-fixed" style="height: calc(100vh - 105px)">
    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <ul class="list-group mg-b-20">
        <li v-for="(p,i) in pays" :key="i" class="list-group-item d-flex align-items-center">
          <img :src="`https://res.gosmart.mn/media/logo/pay/${p.logo}`" class="wd-30 rounded-circle mg-r-15" alt="" />
          <div class="text-left">
            <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">{{p.name}}</h6>
            <div class="d-block tx-11 text-muted">төлбөр төлөх</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <footer class="bd-t pd-y-10 bg-gray-100 pd-x-10">
    <router-link :to="{name: 'penalty', params: {id: $route.params.id }}" class="btn btn-block btn-xs btn-outline-primary btn-uppercase">Буцах</router-link>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      pays:[
        {name: 'ХААН БАНК', logo: 'bank/khanbank.png'},
        {name: 'ТӨРИЙН БАНК', logo: 'bank/statebank.png'},
        {name: 'ХУДАЛДАА ХӨГЖЛИЙН БАНК', logo: 'bank/tdbank.png'},
        {name: 'ХАС БАНК', logo: 'bank/xacbank.png'},
        {name: 'УЛААНБААТАР ХОТЫН БАНК', logo: 'bank/ubcbank.png'},
        {name: 'ЧИНГИС ХААН БАНК', logo: 'bank/ckbank.png'},
        {name: 'MOST MONEY', logo: 'bank/most.png'},
        {name: 'ҮНДЭСНИЙ ХӨРӨНГӨ ОРУУЛАЛТИЙН БАНК', logo: 'bank/nibank.png'},
      ]
    }
  },
}
</script>

<style>

</style>