<template>
  <div class="content" style="padding-top: 80px">
    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <div class="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div class="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div class="wd-100p">
              
              <p class="tx-color-03 tx-16 mg-b-40">Регистерийн дугаарын сүүлийн 4 орон оруулна уу.</p>

              <div class="form-group">
                <label>{{regNo}}
                  {{lastDigits==''?'_ _ _ _':lastDigits}}
                </label>
                <input type="tel" v-model="lastDigits" maxlength="4" class="form-control" placeholder="сүүлийн 4 оронг нөхөж бичнэ үү">
              </div>
              
              <button @click="checkRegister()" class="btn btn-brand-02 btn-block">Шалгах</button>
              
            </div>
          </div><!-- sign-wrapper -->
        </div>
    </div>

  </div>
</template>

<script>
import { request } from '@/api/all';
import { mapActions } from "vuex";
import { setToken } from "@/libs/util";


export default {
  name: 'Home',
  data(){
    return{
      regNo: '',
      lastDigits: '',
      notMyRegisterModal:{
        modal: false,
        plateNo: ''
      }
    }
  },
  mounted() {
    //console.log(this.$route.params.id);
    this.handleLoading({ isLoading: true });
    request({
      api: { method: 'GET', url: 'Auth/PreSms'  },
      params:{id: this.$route.params.id},
      store: this.$store
    }).then(res => {
      this.handleLoading({ isLoading: false });
      if (res !== false) {
        this.regNo= res.data.regNo;
        //console.log(res);
      }
    });
  },
  methods:{
    ...mapActions(["handleLoading"]),
    checkRegister(){
      // TODO id reg 2 iig end shalgana
      let errs=[];
      if(this.lastDigits==''){
        errs.push('Утга хоосон байна');
      } else if(this.lastDigits.length!=4){
        errs.push('4 тоо оруулна уу');
      } 
      // else if(this.lastDigits!='0507'){
      //   errs.push('буруу утга оруулсан байна');
      // }

      if(errs.length==0){
        this.handleLoading({ isLoading: true });
        request({
          api: { method: 'POST', url: 'Auth/CheckSms '  },
          data:{ id: this.$route.params.id, pass: this.lastDigits },
          store: this.$store
        }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            //console.log('Check', res);
           setToken(res.token);
           this.$router.push({name: 'regList', params: {id: this.$route.params.id}}) 
          }
        });
      } else {
        alert(errs[0]);
      }
    },
  }
}
</script>
